import React from 'react';
import CallIcon from '../assets/CallIcon';
import IconLung from '../assets/IconLung';
import IconSmile from '../assets/IconSmile';
import IconOral from '../assets/IconOral';
import WeeklyIcon from '../assets/WeeklyIcon';
import IconSad from '../assets/IconSad';
import IconSkin from '../assets/IconSkin';
import IconHeart from '../assets/IconHeart';
import IconStomach from '../assets/IconStomach';
import IconUnderWear from '../assets/IconUnderWear';
import IconBrain from '../assets/IconBrain';
import IconSleep from '../assets/IconSleep';
import IconMemory from '../assets/IconMemory';
import IconMiscellaneous from '../assets/IconMiscellaneous';
import IconVisual from '../assets/IconVisual';
import IconLove from '../assets/IconLove';
import { StyledBox } from '../common/styledCommonComponents';
import CustomKebab from '../components/TableComponent/CustomKebab';
import { PatientHeader } from '../components/TableComponent/types';
import { darken, rgba } from 'polished';

export enum ModalTypeEnum {
	WEEKLY_MODAL = 'WEEKLY_MODAL',
	BIOMETRIC_MODAL = 'BIOMETRIC_MODAL',
	DAILY_MODAL = 'DAILY_MODAL',
	TREATMENT_MODAL = 'TREATMENT_MODAL',
	CONTACT_MODAL = 'CONTACT_MODAL',
	FREQ_SYMPTOMS_MODAL = 'FREQ_SYMPTOMS_MODAL',
	CONFIG_MODAL = 'CONFIG_MODAL',
	FIND_AUXILIAR_MODAL = 'FIND_AUXILIAR_MODAL',
	CREATE_AUXILIAR_MODAL = 'CREATE_AUXILIAR_MODAL',
	CREATE_DOCTOR_MODAL = 'CREATE_DOCTOR_MODAL',
	STAGE_PATIENT_MODAL = 'STAGE_PATIENT_MODAL',
	DELETE_FILE = 'DELETE_FILE',
	REMOVE_AUXILIAR = 'REMOVE_AUXILIAR',
	REMOVE_DOCTOR = 'REMOVE_DOCTOR',
	ADD_FILE = 'ADD_FILE',
	ADD_STUDY = 'ADD_STUDY',
	PREVIEW_FILE = 'PREVIEW_FILE',
	FREQUENT_MEDICATION_MODAL = 'FREQUENT_MEDICATION_MODAL',
	SUMMARY_QUESTIONARY_MODAL = 'SUMMARY_QUESTIONARY_MODAL',
	TUMOR_PROPORTION_MODAL = 'TUMOR_PROPORTION_MODAL',
	AUXILIAR_MODAL = 'AUXILIAR_MODAL',
	REMOVE_PATIENT = 'REMOVE_PATIENT',
	SYMPTOM_REPORT = 'SYMPTOM_REPORT',
}

export const NumberToWord: Record<number, string> = {
	0: 'Cero',
	1: 'Primera',
	2: 'Segunda',
	3: 'Tercera',
	4: 'Cuarta',
	5: 'Quinta',
	6: 'Sexta',
	7: 'Septima',
	8: 'Octava',
	9: 'Novena',
	10: 'Decima',
	11: 'Onceava',
	12: 'Doceava',
	13: 'Treceava',
	14: 'Catorceava',
	15: 'Quinceava',
	16: 'Dieciseisava',
	17: 'Diecisieteava',
	18: 'Dieciochoava',
};

export enum TreatmentStatusType {
	HAS_TREATMENT = 'HAS_TREATMENT',
	FINISHING_TREATMENT = 'FINISHING_TREATMENT',
	WITHOUT_TREATMENT = 'WITHOUT_TREATMENT',
	IS_FINISHED = 'IS_FINISHED',
	IS_COMPLETED = 'IS_COMPLETED',
}

export interface ITreatment {
	sealed?: boolean;
	id: string;
	primaryTreatment: string;
	systematicTreatment: string;
	startDate: string;
	estimateFinishDate: string;
	finishDate?: string;
	treatmentLine: number;
	endingMotive?: string;
	medicationMotive: string;
	otherMotive: string;
	medications: { id: string; name: string }[];
}

export interface IBiomarker {
	id: string;
	sample: string;
	biomarker: string;
	evaluation: string;
}

export interface IProgression {
	id: string;
	progressionDate: string;
	progressionSite: string;
	treatment: ITreatment;
}

export interface ISetback {
	id: string;
	setbackDate: string;
	setbackSite: string;
	metastasisSite: string;
	treatment: ITreatment;
}

export enum InputTypeEnum {
	BUTTON = 'BUTTON',
	TEXTFIELD = 'TEXTFIELD',
	LIST = 'LIST',
	SELECTOR = 'SELECTOR',
	DATEFIELD = 'DATEFIELD',
	TNMFIELD = 'TNMFIELD',
	ACTIONFIELD = 'ACTIONFIELD',
	TREATMENTFIELD = 'TREATMENTFIELD',
	CONDITIONAL = 'CONDITIONAL',
	BIOMARKER_ROW = 'BIOMARKER_ROW',
	SETBACK_ROW = 'SETBACK_ROW',
	MEDICATION_ROW = 'MEDICATION_ROW',
	ADD_SECTION = 'ADD_SECTION',
	MEDICATION_TREATMENT_ROW = 'MEDICATION_TREATMENT_ROW',
	ACCORDION = 'ACCORDION',
	PROGRESSION_ROW = 'PROGRESSION_ROW',
	TITLE = 'TITLE',
	SMOKER = 'SMOKER',
}

export enum actionTypeEnum {
	ADD_BIOMARKER = 'ADD_BIOMARKER',
	ADD_SETBACK = 'ADD_SETBACK',
	ADD_TREATMENT = 'ADD_TREATMENT',
	ADD_PROGRESSION = 'ADD_PROGRESSION',
	ADD_MEDICATION_TREATMENT_MODAL = 'ADD_MEDICATION_TREATMENT_MODAL',
	ADD_MEDICATION = 'ADD_MEDICATION',
	FINISH_TREATMENT = 'FINISH_TREATMENT',
	FINISH_TREATMENT_SETBACK = 'FINISH_TREATMENT_SETBACK',
	FINISH_TREATMENT_PROGRESSION = 'FINISH_TREATMENT_PROGRESSION',
	DELETE_MEDICATION = 'DELETE_MEDICATION',
}

export const TNMOptions = {
	tumor: {
		no_value: 'Seleccione T',
		TX: 'TX',
		T0: 'T0',
		T1: 'T1',
		T1a: 'T1a',
		T1ami: 'T1ami',
		T1b: 'T1b',
		T1c: 'T1c',
		T1aac: 'T1aac',
		T1aab: 'T1aab',
		T2: 'T2',
		T2a: 'T2a',
		T2b: 'T2b',
		T3: 'T3',
		T4: 'T4',
		T4a: 'T4a',
		T4b: 'T4b',
		T4c: 'T4c',
		T4d: 'T4d',
		Tis: 'Tis',
		TisDCIS: 'TisDCIS',
		TisCLIS: 'TisCLIS',
		TisPaget: 'TisPaget',
	},
	nodule: {
		no_value: 'Seleccione N',
		N0: 'N0',
		N0i: 'N0i',
		N1: 'N1',
		N1a: 'N1a',
		N1b: 'N1b',
		N1c: 'N1c',
		N2: 'N2',
		N2a: 'N2a',
		N2b: 'N2b',
		N3: 'N3',
		N3a: 'N3a',
		N3b: 'N3b',
		N3c: 'N3c',
	},
	metastasis: {
		no_value: 'Seleccione M',
		M0: 'M0',
		cM0Mic: 'cM0Mic',
		M1: 'M1',
		M1a: 'M1a',
		M1b: 'M1b',
		M1c: 'M1c',
		cM1: 'cM1',
		PM1: 'PM1',
	},
	cancerStage: {
		no_value: 'Seleccione Estadio',
		Estadio0: 'Estadio0',
		EstadioIA: 'EstadioIA',
		EstadioIA1: 'EstadioIA1',
		EstadioIA2: 'EstadioIA2',
		EstadioIA3: 'EstadioIA3',
		EstadioIB: 'EstadioIB',
		EstadioIIA: 'EstadioIIA',
		EstadioIIB: 'EstadioIIB',
		EstadioIIIA: 'EstadioIIIA',
		EstadioIIIB: 'EstadioIIIB',
		EstadioIIIC: 'EstadioIIIC',
		EstadioIV: 'EstadioIV',
		EstadioIVA: 'EstadioIVA',
		EstadioIVB: 'EstadioIVB',
		EstadioIVC: 'EstadioIVC',
	},
};

export const PatientsListHeaderConfig = [
	{
		text: 'Pacientes Activos',
		number: '47',
		positive: false,
		pillText: '-2',
		pillDetail: '4 pacientes que se volvieron inactivos',
	},
	{
		text: 'Pacientes Totales',
		number: '55',
		positive: true,
		pillText: '+4',
		pillDetail: '4 nuevos pacientes',
	},
	{
		text: 'Pacientes en Tratamiento',
		number: '42',
		positive: true,
		pillText: '+4',
		pillDetail: '4 nuevos pacientes en tratamiento ',
	},
];

export enum ProfileConfigButtonType {
	FORM = 'form',
	CONTACT = 'contact',
	CONFIG = 'config',
}

export const ProfileConfigButton = [
	{
		text: 'Contactar',
		icon: CallIcon,
		type: ProfileConfigButtonType.CONTACT,
		variant: 'filled',
	},
	{
		text: 'Formulario',
		type: ProfileConfigButtonType.FORM,
		variant: 'ghost',
	},
	{
		text: 'Configuración',
		type: ProfileConfigButtonType.CONFIG,
		variant: 'ghost',
	},
];

// Tables

// Patient list

// Header

export const PatientListHeaderConst: PatientHeader[] = [
	{
		field: 'avatarId',
		label: ' ',
		sortId: '',
		width: 50,
		minWidth: '82px',
		maxWidth: '82px',
		css: {
			color: ' #9357f7 !important',
			fontSize: '12px',
			paddingLeft: '30px',
		},
		render: ({ props }: any) =>
			getProfileImageFromName({
				name: props[0],
				surname: props[1],
				size: {
					width: '28px',
					height: '28px',
				},
			}),
	},
	{
		field: 'fullname',
		label: 'Nombre y Apellido',
		button: true,
		sortId: 'name',
		width: 150,
		minWidth: '169.95px',
		maxWidth: '169.95px',
		css: { color: ' #9357f7 !important', fontSize: '12px' },
		render: (props: any) => <p>{props.children}</p>,
	},
	{
		field: 'mainTumor',
		label: 'Tumor Principal',
		button: true,
		sortId: 'organ',
		width: 150,
		minWidth: '125.62px',
		maxWidth: '125.62px',
		css: { color: ' #9357f7 !important', fontSize: '12px' },
		render: ({ children }: any) => <>{children}</>,
	},
	{
		field: 'status',
		label: 'Estado',
		button: true,
		sortId: 'status',
		width: 300,
		minWidth: '120.02px',
		maxWidth: '120.02px',
		css: {
			color: ' #9357f7 !important',
			fontSize: '12px',
			paddingRight: '30px',
		},
		render: ({ children }: any) => renderStatusPill(children),
	},
	{
		field: 'lastAlert',
		label: 'Ultima Alerta',
		button: true,
		sortId: 'lastAlert',
		width: 150,
		minWidth: '169.95px',
		maxWidth: '169.95px',
		css: { color: ' #9357f7 !important', fontSize: '12px' },
		render: ({ children }: any) => <>{children}</>,
	},
	{
		field: 'lastActivity',
		label: 'Ultima actividad',
		button: true,
		sortId: 'lastActivity',
		width: 150,
		minWidth: '303.84px',
		maxWidth: '303.84px',
		css: { color: ' #9357f7 !important', fontSize: '12px' },
		render: ({ children }: any) => <>{children}</>,
	},
	{
		field: 'kebab',
		label: ' ',
		sortId: 'emptyBox',
		width: 50,
		minWidth: '303.84px',
		maxWidth: '303.84px',
		css: { color: ' #9357f7 !important', fontSize: '12px' },
		render: (props: any) => (
			<CustomKebab kebabOptions={props.children}></CustomKebab>
		),
	},
];

export const AuxiliarListHeaderConst: PatientHeader[] = [
	{
		field: 'avatarId',
		label: ' ',
		sortId: '',
		width: 50,
		minWidth: '82px',
		maxWidth: '82px',
		css: {
			color: ' #9357f7 !important',
			fontSize: '12px',
			paddingLeft: '30px',
		},
		render: ({ props }: any) =>
			getProfileImageFromName({
				name: props[0],
				surname: props[1],
				size: {
					width: '28px',
					height: '28px',
				},
			}),
	},
	{
		field: 'fullname',
		label: 'Nombre y Apellido',
		button: true,
		sortId: 'fullname',
		width: 150,
		minWidth: '169.95px',
		maxWidth: '169.95px',
		css: { color: ' #9357f7 !important', fontSize: '12px' },
		render: (props: any) => <p>{props.children}</p>,
	},
	{
		field: 'rol',
		label: 'Rol',
		button: true,
		sortId: 'rol',
		width: 150,
		minWidth: '125.62px',
		maxWidth: '125.62px',
		css: { color: ' #9357f7 !important', fontSize: '12px' },
		render: ({ children }: any) => <>{children}</>,
	},
	{
		field: 'lastSignUp',
		label: 'Último inicio de sesión',
		button: true,
		sortId: 'lastSignUp',
		width: 150,
		minWidth: '169.95px',
		maxWidth: '169.95px',
		css: { color: ' #9357f7 !important', fontSize: '12px' },
		render: ({ children }: any) => <>{children}</>,
	},
	{
		field: 'kebab',
		label: ' ',
		sortId: 'emptyBox',
		width: 50,
		minWidth: '303.84px',
		maxWidth: '303.84px',
		css: { color: ' #9357f7 !important', fontSize: '12px' },
		render: (props: any) => (
			<CustomKebab kebabOptions={props.children}></CustomKebab>
		),
	},
];

export const getUserStatusLabel = (
	type: 'Accepted' | 'ACTIVE' | 'innactive' | 'Urgent' | 'Waiting' | 'Pending',
) => {
	return {
		Accepted: 'Activo',
		ACTIVE: 'Activo',
		innactive: 'Inactivo',
		Urgent: 'Urgencia',
		Waiting: 'En aprobacion',
		Pending: 'En espera',
	}[type];
};
export const getPollStatusLabel = (
	type: 'Completed' | 'Incomplete' | 'Empty',
) => {
	return {
		Completed: 'Completado',
		Incomplete: 'Incompleto',
		Empty: 'Sin Arrancar',
	}[type];
};

export enum CategoryList {
	Respiratorio = 'Respiratorio',
	Oral = 'Oral',
	Neurologico = 'Neurológico',
	Sueño = 'Sueño',
	Sexual = 'Sexual',
	Gastrointestinal = 'Gastrointestinal',
	Cardiaco = 'Cardiaco',
	Cutaneo = 'Cutaneo',
	Ánimo = 'Ánimo',
	Visual = 'Visual',
	Memoria = 'Memoria',
	Dolor = 'Dolor',
	Genital = 'Genital',
	Miscelaneo = 'Miscelaneo',
}

export const getIconByCategory = (category: CategoryList) => {
	switch (category) {
		case CategoryList.Respiratorio:
			return <IconLung />;
		case CategoryList.Oral:
			return <IconOral />;
		case CategoryList.Dolor:
			return <IconSad />;
		case CategoryList.Cutaneo:
			return <IconSkin />;
		case CategoryList.Cardiaco:
			return <IconHeart />;
		case CategoryList.Gastrointestinal:
			return <IconStomach />;
		case CategoryList.Genital:
			return <IconUnderWear />;
		case CategoryList.Neurologico:
			return <IconBrain />;
		case CategoryList.Sueño:
			return <IconSleep />;
		case CategoryList.Memoria:
			return <IconMemory />;
		case CategoryList.Miscelaneo:
			return <IconMiscellaneous />;
		case CategoryList.Visual:
			return <IconVisual />;
		case CategoryList.Sexual:
			return <IconLove />;
		case CategoryList.Ánimo:
			return <IconSmile />;
		default:
			return <WeeklyIcon />;
	}
};

export const textColorStatus: {
	[key in
		| 'Accepted'
		| 'ACTIVE'
		| 'innactive'
		| 'Urgent'
		| 'Waiting'
		| 'Pending']: string;
} = {
	Accepted: '#1D6535',
	ACTIVE: '#1D6535',
	innactive: '#5784F7',
	Urgent: '#5784F7',
	Waiting: '#EA8053',
	Pending: '#EA8053',
};

export const pollTextColorStatus = {
	Completed: '#1D6535',
	Incomplete: '#EA8053',
	Empty: '#5784F7',
};

export const backgroundPollColorStatus = {
	Completed: '#BEE8CF',
	Empty: '#DFE8FF',
	Incomplete: '#F9E0D6',
};

export const backgroundColorStatus = {
	Accepted: '#BEE8CF',
	ACTIVE: '#BEE8CF',
	Urgent: '#DFE8FF',
	innactive: '#DFE8FF',
	Waiting: '#F9E0D6',
	Pending: '#F9E0D6',
};

export const FrequencyAnswers = [
	{
		label: 'Nunca',
		value: 0,
	},
	{
		label: 'Raramente',
		value: 1,
	},
	{
		label: 'Ocasionalmente',
		value: 2,
	},
	{
		label: 'Frecuentemente',
		value: 3,
	},
	{
		label: 'Constantemente',
		value: 4,
	},
];
export const SeverityAnswers = [
	{
		label: 'Ninguna',
		value: 0,
	},
	{
		label: 'Leve',
		value: 1,
	},
	{
		label: 'Moderado',
		value: 2,
	},
	{
		label: 'Severo',
		value: 3,
	},
	{
		label: 'Muy severo',
		value: 4,
	},
];
export const InterferenceAnswers = [
	{
		label: 'No del todo',
		value: 0,
	},
	{
		label: 'Un poco',
		value: 1,
	},
	{
		label: 'Moderada',
		value: 2,
	},
	{
		label: 'Bastante',
		value: 3,
	},
	{
		label: 'Mucho',
		value: 4,
	},
];
export const AmountAnswers = [
	{
		label: 'No del todo',
		value: 0,
	},
	{
		label: 'Un poco',
		value: 1,
	},
	{
		label: 'Un poco',
		value: 2,
	},
	{
		label: 'Bastante',
		value: 3,
	},
	{
		label: 'Mucho',
		value: 4,
	},
];
export const PresenceAnswers = [
	{
		label: 'No',
		value: 0,
	},
	{
		label: 'Si',
		value: 1,
	},
];

export const getAnswersByType = (
	type: 'Severity' | 'Frequency' | 'Interference' | 'Amount' | 'Presence',
) => {
	switch (type) {
		case 'Severity':
			return SeverityAnswers;
		case 'Frequency':
			return FrequencyAnswers;
		case 'Interference':
			return InterferenceAnswers;
		case 'Amount':
			return AmountAnswers;
		case 'Presence':
			return PresenceAnswers;
	}
};

export const translateQuestion = (
	type: 'Severity' | 'Frequency' | 'Interference' | 'Amount' | 'Presence',
) => {
	switch (type) {
		case 'Severity':
			return 'Severidad';
		case 'Frequency':
			return 'Frecuencia';
		case 'Interference':
			return 'Interferencia';
		case 'Amount':
			return 'Cantidad';
		case 'Presence':
			return 'Presencia';
	}
};

export const pollBackgroundColorStatus = {
	completed: '#BEE8CF',
	incomplete: '#DFE8FF',
	unstarted: '#F9E0D6',
};

export const renderStatusPill = (
	type: 'Accepted' | 'ACTIVE' | 'innactive' | 'Urgent' | 'Waiting' | 'Pending',
) => {
	let stateWidth;
	switch (type) {
		case 'Accepted':
			stateWidth = '60px';
			break;
		case 'ACTIVE':
			stateWidth = '60px';
			break;
		case 'Waiting':
			stateWidth = '106px';
			break;
		case 'Urgent':
			stateWidth = '75px';
			break;
		default:
			stateWidth = '60px';
	}
	return (
		<StyledBox
			css={{
				color: textColorStatus[type],
				backgroundColor: backgroundColorStatus[type],
				borderRadius: '20px',
				width: stateWidth,
				height: '23px',
				display: 'flex',
				fontSize: '12px',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			{getUserStatusLabel(type)}
		</StyledBox>
	);
};

export const renderPollPill = (type: 'Completed' | 'Incomplete' | 'Empty') => {
	return (
		<StyledBox
			css={{
				color: pollTextColorStatus[type],
				backgroundColor: backgroundPollColorStatus[type],
				borderRadius: '10px',
				width: '120px',
				height: '24px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			{getPollStatusLabel(type)}
		</StyledBox>
	);
};

export const PollResultsHeaderConst = [
	{
		label: ' ',
		sortId: '',
	},
	{
		label: 'Fecha de Realización',
		sortId: '',
	},
	{
		label: 'Estado',
		sortId: '',
	},
];
export const CapitalizeText = (text: string) => {
	if (!text) return '';
	return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

interface ProfileImageProps {
	name: string;
	surname?: string;
	size: {
		width: string | number;
		height: string | number;
		fontSize?: string | number;
	};
	withMargin?: number;
	color?: string;
}

export const daysOrder: { [key: string]: number } = {
	MONDAY: 1,
	TUESDAY: 2,
	WEDNESDAY: 3,
	THURSDAY: 4,
	FRIDAY: 5,
	SATURDAY: 6,
	SUNDAY: 7,
};

export function sortDays(days: any[]) {
	days.sort((a, b) => {
		return daysOrder[a] - daysOrder[b];
	});
}

export const daysTranslate: { [key: string]: string } = {
	MONDAY: 'Lunes',
	TUESDAY: 'Martes',
	WEDNESDAY: 'Miércoles',
	THURSDAY: 'Jueves',
	FRIDAY: 'Viernes',
	SATURDAY: 'Sábado',
	SUNDAY: 'Domingo',
};

export const getProfileImageFromName = ({
	name,
	surname,
	size,
	withMargin,
	color: colorProp,
}: ProfileImageProps) => {
	const fullname = `${name} ${surname ?? ''}`.trim();
	const sumOfChars = fullname.length % 5;
	let color = [
		'106,209,141',
		'236,164,133',
		'205,125,227',
		'172,122,255',
		'87,132,247',
	][sumOfChars];
	let letters = '';
	const splittedName = fullname.split(' ');
	splittedName.forEach((item) => {
		if (letters.length === 2) return;
		letters += item.charAt(0);
	});
	return (
		<StyledBox
			css={{
				margin: withMargin ? 2 : '0',
				width: size.width,
				height: size.height,
				minWidth: size.width,
				minHeight: size.height,
				borderRadius: '50px',
				backgroundColor: colorProp
					? rgba(colorProp, 0.4)
					: `rgb(${color},0.4)`,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				color: colorProp
					? darken(0.2, colorProp)
					: darken(0.2, `rgb(${color})`),
				fontWeight: 400,
				textTransform: 'uppercase',
				fontSize: size?.fontSize ?? '12px',
			}}
		>
			{letters}
		</StyledBox>
	);
};

export const parseDataWithYear = (dayItem: string) => {
	return parseData(dayItem) + ' de ' + new Date(dayItem).getUTCFullYear();
};

export const parseData = (DayItem: Date | string) => {
	let dayObject = new Date(DayItem);
	let day =
		dayObject.getUTCDate().toString() +
		' de ' +
		getMonth(dayObject.getUTCMonth() + 1);
	return day;
};

const getMonth = (number: number) => {
	const months = [
		'Enero',
		'Febrero',
		'Marzo',
		'April',
		'Mayo',
		'Junio',
		'Julio',
		'Agosto',
		'Septiembre',
		'Octubre',
		'Noviembre',
		'Diciembre',
	];
	if (number > 0 && number < 13) return months[number - 1];
	return null;
};

export enum EndpointsListType {
	ORDEN = 'ORDEN',
	FILTER = 'FILTER',
	TABLE = 'TABLE',
}

export const parseNewDate = (date: string) => {
	const newDate = new Date(date);
	return `${newDate.getFullYear()}-${newDate.getMonth()}-${newDate.getDate()}`;
	// const dateInformation = date.split('-');
	// const formattedDate = `${dateInformation[1]}/${dateInformation[2]}/${dateInformation[0]}`;
	// return new Date(formattedDate);
};
