import React from 'react';
import { StyledBox } from '../../../../common/styledCommonComponents';
import StyledText from '../../../../common/components/Text';
import { useTheme } from 'styled-components';
import { Symptom } from '../../../../redux/api/types';

interface SymptomListProps {
	symptoms: Symptom[];
}

const SymptomList = ({ symptoms }: SymptomListProps) => {
	const theme = useTheme();
	const LIMIT = 3;
	return (
		<StyledBox
			css={{
				display: 'flex',
				flexDirection: 'column',
				gap: 4,
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			{symptoms.slice(0, LIMIT).map((symptom) => (
				<StyledBox
					key={symptom.symptomId}
					css={{
						display: 'flex',
						gap: 4,
						justifyContent: 'space-between',
						width: '100%',
					}}
				>
					<StyledText
						variant="buttonLarge"
						css={{
							color: symptom.redFlag ? theme.error400 : theme.warning500,
						}}
					>
						{symptom.symptom}
					</StyledText>
					<StyledBox
						css={{
							display: 'inline-flex',
							padding: '4px 12px',
							alignItems: 'flex-start',
							gap: '10px',
							borderRadius: '20px',
							backgroundColor: symptom.redFlag
								? theme.error400
								: theme.warning500,
							height: 'min-content',
						}}
					>
						<StyledText
							variant="buttonSmall"
							css={{ width: 'max-content', color: 'white' }}
						>
							{`Grado ${symptom.intensityGrade}`}
						</StyledText>
					</StyledBox>
				</StyledBox>
			))}
			{symptoms.length > LIMIT && (
				<StyledBox
					css={{
						display: 'inline-flex',
						padding: '4px 12px',
						alignItems: 'flex-start',
						gap: '10px',
						borderRadius: '20px',
						backgroundColor: theme.warning500,
					}}
				>
					<StyledText
						variant="buttonSmall"
						css={{ width: 'max-content', color: 'white' }}
					>
						{`+ ${symptoms.length - LIMIT}`}
					</StyledText>
				</StyledBox>
			)}
		</StyledBox>
	);
};

export default SymptomList;
