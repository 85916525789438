import React from 'react';
import { StyledBox, StyledP } from '../../../../common/styledCommonComponents';
import StyledText from '../../../../common/components/Text';
import { useTheme } from 'styled-components';
import SymptomList from '../SymptomList';
import { SymptomReport } from '../../../../redux/api/types';
import moment from 'moment/moment';
import { setModalOpen } from '../../../../redux/slices/utilsSlice';
import { ModalTypeEnum } from '../../../../utils/utils';
import { useOncoDispatch } from '../../../../hooks/redux-hook';

const SymptomCard = ({
	checked,
	createdAt,
	reportId,
	symptoms,
}: SymptomReport) => {
	const theme = useTheme();
	const hasRedFlag = symptoms.some((symptom) => symptom.redFlag);
	const dispatch = useOncoDispatch();

	const handleOpenModal = (report: any) => {
		dispatch(
			setModalOpen({
				open: true,
				type: ModalTypeEnum.SYMPTOM_REPORT,
				report: report,
			}),
		);
	};

	return (
		<StyledBox
			onClick={() =>
				handleOpenModal({ checked, createdAt, reportId, symptoms })
			}
			css={{
				display: 'flex',
				position: 'relative',
				flexDirection: 'column',
				padding: '12px 20px',
				gap: 8,
				borderRadius: 16,
				height: '100%',
				width: '250px',
				alignItems: 'center',
				backgroundColor: hasRedFlag ? '#FFF7F6' : '#FFFAF2',
				border: `1px solid ${hasRedFlag ? '#EC7F7433' : '#FFF0D7'}`,
				boxShadow: `0px 1px 10px 0px ${
					hasRedFlag ? '#EC7F741A' : '#F2CF981A'
				}`,
				cursor: 'pointer',
			}}
		>
			<StyledText
				variant="body3"
				css={{
					color: theme.gray700,
					textAlign: 'center',
				}}
			>
				{moment(createdAt).fromNow()}
			</StyledText>
			<SymptomList
				symptoms={
					symptoms.length > 1
						? [...symptoms]?.sort(
								// show redFlags first
								(a, b) => Number(b.redFlag) - Number(a.redFlag),
						  )
						: symptoms
				}
			/>
			{!checked && (
				<StyledBox
					css={{
						position: 'absolute',
						top: 0,
						right: -4,
						height: 12,
						width: 12,
						borderRadius: '50%',
						backgroundColor: theme.error400,
					}}
				></StyledBox>
			)}
		</StyledBox>
	);
};

export default SymptomCard;
