import React from 'react';
import {
	StyledBox,
	StyledColumn,
	StyledRow,
} from '../../../../common/styledCommonComponents';
import { useTheme } from 'styled-components';
import StyledText from '../../../../common/components/Text';
import moment from 'moment/moment';

import {
	useGetSymptomReportsQuery,
	useSymptomsQuery,
} from '../../../../redux/api/symptomsApi';
import { StyledSymptomTable } from './styles';
import Moment from 'react-moment';
import { setModalOpen } from '../../../../redux/slices/utilsSlice';
import { ModalTypeEnum } from '../../../../utils/utils';
import { useOncoDispatch } from '../../../../hooks/redux-hook';
import { useParams } from 'react-router';
import {
	SymptomReport,
	SymptomsReportResponse,
} from '../../../../redux/api/types';

const SymptomsDashboard = () => {
	const theme = useTheme();
	const { patientId } = useParams();
	const { data: symptomsList } = useSymptomsQuery();
	const {
		data: patientReports,
		isSuccess: isSuccessClosedForms,
		isLoading,
	} = useGetSymptomReportsQuery({ patientId, firstPage: 0, pageSize: 50 });
	const dispatch = useOncoDispatch();

	const reports: SymptomReport[] = [
		{
			reportId: 'a83c37d0-5e15-40fe-8f43-318a04d0c00c',
			createdAt: 1736275301054,
			checked: true,
			symptoms: [
				{
					symptomId: 'b827894e-0466-4578-a00c-7a326d1717cf',
					symptom: 'Diarrea',
					intensityGrade: 4,
					redFlag: true,
					intensityDescription:
						'Deposiciones incontrolables (incontinencia o uso de pañal)',
				},
				{
					symptomId: 'd900dc34-813a-4d27-8459-cfb8bf75f222',
					symptom: 'Estreñimiento',
					intensityGrade: 1,
					redFlag: false,
					intensityDescription: 'Síntomas ocasionales o intermitentes',
				},
			],
		},
		{
			reportId: 'ecda1a43-49ad-4ee6-a884-c9fb6b6999ad',
			createdAt: 1734626992652,
			checked: true,
			symptoms: [
				{
					symptomId: 'b827894e-0466-4578-a00c-7a326d1717cf',
					symptom: 'Diarrea',
					intensityGrade: 4,
					redFlag: true,
					intensityDescription:
						'Deposiciones incontrolables (incontinencia o uso de pañal)',
				},
				{
					symptomId: 'd900dc34-813a-4d27-8459-cfb8bf75f222',
					symptom: 'Estreñimiento',
					intensityGrade: 1,
					redFlag: false,
					intensityDescription: 'Síntomas ocasionales o intermitentes',
				},
			],
		},
		{
			reportId: '1685cf7c-204a-41b6-8379-47c4d3dcc33b',
			createdAt: 1734626246129,
			checked: false,
			symptoms: [
				{
					symptomId: 'd900dc34-813a-4d27-8459-cfb8bf75f222',
					symptom: 'Estreñimiento',
					intensityGrade: 1,
					redFlag: false,
					intensityDescription: 'Síntomas ocasionales o intermitentes',
				},
			],
		},
	];

	const handleRowClick = (report: SymptomReport) => {
		dispatch(
			setModalOpen({
				open: true,
				type: ModalTypeEnum.SYMPTOM_REPORT,
				report: report,
			}),
		);
	};
	const customFromNowTodayAndYesterday = (date: number) => {
		const d = moment(date);

		if (d.isSame(moment(), 'day')) {
			return 'Hoy';
		} else if (d.isSame(moment().subtract(1, 'day'), 'day')) {
			return 'Ayer';
		} else {
			return d.fromNow();
		}
	};

	return (
		<StyledColumn
			css={{
				// maxWidth: '1300px',
				maxHeight: '700px',
				display: 'flex',
				flexDirection: 'column',
				gap: 16,
				width: '100%',
				padding: '24px',
				borderRadius: '20px',
				background: '#FFF',
				boxShadow: '0px 1px 25px 0px rgba(175, 126, 255, 0.10)',
			}}
		>
			<StyledBox
				css={{
					display: 'flex',
					paddingBottom: 8,
					justifyContent: 'space-between',
					alignItems: 'flex-end',
					width: '100%',
					borderBottom: `1px solid ${theme.gray50}`,
				}}
			>
				<StyledText
					variant="body2Bold"
					css={{
						color: theme.gray700,
						height: '17px',
					}}
				>
					Síntomas reportados
				</StyledText>
			</StyledBox>
			<StyledBox
				css={{
					display: 'flex',
					flex: 1,
					gap: '16px',
					overflowX: 'auto',
				}}
			>
				<StyledSymptomTable>
					<thead>
						<tr>
							<th></th>
							{symptomsList?.map((symptom) => (
								<th key={symptom.id}>
									<StyledText
										variant="body1SemiBold"
										css={{
											color: theme.gray800,
											textAlign: 'center',
										}}
									>
										{symptom.name}
									</StyledText>
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{patientReports?.reports?.map((report) => (
							<tr
								key={report.reportId}
								onClick={() => handleRowClick(report)}
							>
								<td>
									<StyledText
										variant="body3"
										css={{ color: theme.gray800 }}
									>
										{moment(report.createdAt).isBetween(
											moment().subtract(2, 'days').startOf('day'),
											moment().endOf('day'),
											null,
											'[]',
										) ? (
											`${customFromNowTodayAndYesterday(
												report.createdAt,
											)}, ${moment(report.createdAt).format(
												'HH:mm',
											)}`
										) : (
											<Moment format="DD/MM/YYYY, HH:mm">
												{report.createdAt}
											</Moment>
										)}
									</StyledText>
								</td>
								{symptomsList?.map((symptom) => {
									const matchingSymptom = report.symptoms.find(
										(s) => s.symptomId === symptom.id,
									);

									const backgroundColor = !matchingSymptom
										? theme.gray50
										: matchingSymptom.redFlag
										? theme.error400
										: theme.warning500;

									const fontColor = !matchingSymptom
										? theme.gray50
										: matchingSymptom.redFlag
										? theme.error700
										: theme.warning700;

									return (
										<td
											key={symptom.id}
											style={{
												verticalAlign: 'center',
												alignContent: 'center',
												// display: 'grid',
												// placeContent: 'center',
												// alignItems: 'center',
											}}
										>
											<StyledRow css={{ justifyContent: 'center' }}>
												<StyledBox
													css={{
														backgroundColor: backgroundColor,
														color: fontColor,
														height: '18px',
														width: '18px',
														borderRadius: '2px',
														textAlign: 'center',
														display: 'grid',
														placeContent: 'center',
													}}
												>
													<StyledText variant="body3">
														{matchingSymptom?.intensityGrade}
													</StyledText>
												</StyledBox>
											</StyledRow>
										</td>
									);
								})}
							</tr>
						))}
					</tbody>
				</StyledSymptomTable>
			</StyledBox>
		</StyledColumn>
	);
};

export default SymptomsDashboard;
