import React, { useEffect, useState } from 'react';
import {
	StyledBox,
	StyledColumn,
	StyledRow,
	StyledScreen,
} from '../../../common/styledCommonComponents';
import SymptomsDashboard from '../components/SymptomsDashboard';
import { SymptomReport } from '../../../redux/api/types';

const SymptomsReportScreen = () => {
	// const { patientId } = useParams();

	const [reports, setReports] = useState<SymptomReport[]>([]);

	return (
		<StyledScreen
			css={{
				display: 'flex',
				flexDirection: 'row',
				padding: '30px',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			{/* {isLoading ? (
				<StyledColumn
					css={{
						alignItems: 'center',
						justifyContent: 'center',
						width: '100%',
						height: '100%',
						display: 'inline-flex',
						flexDirection: 'column',
						gap: '16px',
					}}>
					<StyledCircularProgress size={50} />
				</StyledColumn>
			) : isError ? (
				<NotFoundMessage />
			) : ( */}
			<SymptomsDashboard />
			{/* )} */}
		</StyledScreen>
	);
};

export default SymptomsReportScreen;
