import { api } from './api';
import { Recommendation, SymptomDetail, SymptomsReportResponse } from './types';

export const symptomsApi = api.injectEndpoints({
	endpoints: (builder) => ({
		symptoms: builder.query<SymptomDetail[], void>({
			query: () => ({ url: '/symptom', method: 'GET' }),
		}),
		getSymptomReports: builder.query<SymptomsReportResponse, any>({
			query: ({ patientId, firstPage, pageSize }) => ({
				url: `symptom/report/patients/${patientId}`,
				method: 'GET',
				params: { firstPage, pageSize },
			}),
		}),
		getReportRecommendations: builder.query<Recommendation[], string>({
			query: (reportId) => ({
				url: `symptom/report/${reportId}/recommendations`,
				method: 'GET',
			}),
		}),
	}),
});

export const {
	useSymptomsQuery,
	useGetSymptomReportsQuery,
	useGetReportRecommendationsQuery,
} = symptomsApi;

export const {
	endpoints: { symptoms },
} = symptomsApi;
