import React, { useEffect } from 'react';
import {
	useOncoDispatch,
	useOncoSelector,
} from '../../../../../hooks/redux-hook';
import Modal from '../Modal';
import StyledText from '../../../../../common/components/Text';
import {
	StyledBox,
	StyledColumn,
} from '../../../../../common/styledCommonComponents';
import { useLazyGetPatientDataQuery } from '../../../../../redux/api/patientApi';
import { useParams } from 'react-router';
import { setMedicalStatus } from '../../../../../redux/slices/utilsSlice';
import { useGetReportRecommendationsQuery } from '../../../../../redux/api/symptomsApi';
import { useTheme } from 'styled-components';
import { Symptom } from '../../../../../redux/api/types';
import { StyledCircularProgress } from '../../../../CustomCircularProgress/styles';

interface SymptomReportModalProps {
	handleOnClose: () => void;
}

const SymptomReportModal = ({ handleOnClose }: SymptomReportModalProps) => {
	const dispatch = useOncoDispatch();
	const theme = useTheme();
	const { report } = useOncoSelector((state) => state.utilsSlice);
	const { patientId } = useParams();
	const [refetchPatientData, { data: dataPatient, isSuccess, isLoading }] =
		useLazyGetPatientDataQuery();

	const { data: reportRecommendations, isLoading: recommendationLoading } =
		useGetReportRecommendationsQuery(
			// @ts-ignore
			report.reportId,
		);

	const loading = isLoading || recommendationLoading;
	useEffect(() => {
		refetchPatientData(patientId).then((res: any) => {
			dispatch(setMedicalStatus(res.data.medicalStatus));
		});
	}, [patientId]);

	return (
		<Modal
			onClose={handleOnClose}
			width={480}
			header={
				<StyledText variant="h5" css={{ color: '#000' }}>
					{loading ? '' : `${dataPatient?.name} reportó síntomas`}
				</StyledText>
			}
			body={
				<StyledBox
					css={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						width: '100%',
						gap: 16,
						paddingBottom: 24,
					}}
				>
					{loading ? (
						<StyledColumn
							css={{
								alignItems: 'center',
								justifyContent: 'center',
								width: '100%',
								height: 400,
							}}
						>
							<StyledCircularProgress size={50} />
						</StyledColumn>
					) : (
						<>
							{
								// @ts-ignore
								report?.symptoms.map((symptom) => (
									<SymptomCard key={symptom.symptom} {...symptom} />
								))
							}
							{reportRecommendations &&
								reportRecommendations?.length > 0 && (
									<StyledText variant={'body1'}>
										Recomendaciones
									</StyledText>
								)}
							<StyledColumn css={{ gap: 8 }}>
								{reportRecommendations?.map((recommendation) => (
									<StyledColumn
										css={{
											padding: 16,
											gap: 4,
											borderRadius: 16,
											borderWidth: 1,
											borderStyle: 'solid',
											borderColor: theme.gray200,
										}}
									>
										<StyledText variant={'buttonLarge'}>
											{recommendation.name}
										</StyledText>
										<StyledText variant={'body3'}>
											{recommendation.description}
										</StyledText>
									</StyledColumn>
								))}
							</StyledColumn>
						</>
					)}
				</StyledBox>
			}
		/>
	);
};

const SymptomCard = ({
	symptom,
	intensityDescription,
	redFlag,
	intensityGrade,
}: Symptom) => {
	const theme = useTheme();
	return (
		<StyledBox
			css={{
				display: 'flex',
				position: 'relative',
				flexDirection: 'column',
				padding: '12px 20px',
				gap: 8,
				borderRadius: 16,
				width: '100%',
				alignItems: 'center',
				backgroundColor: redFlag ? '#FFF7F6' : '#FFFAF2',
				border: `1px solid ${redFlag ? '#EC7F7433' : '#FFF0D7'}`,
				boxShadow: `0px 1px 10px 0px ${
					redFlag ? '#EC7F741A' : '#F2CF981A'
				}`,
			}}
		>
			<StyledBox
				css={{
					display: 'flex',
					flexDirection: 'column',
					gap: 4,
					justifyContent: 'space-between',
					width: '100%',
				}}
			>
				<StyledBox
					css={{
						display: 'flex',
						gap: 4,
						justifyContent: 'space-between',
						width: '100%',
					}}
				>
					<StyledText
						variant="buttonLarge"
						css={{
							color: redFlag ? theme.error400 : theme.warning500,
						}}
					>
						{symptom}
					</StyledText>
					<StyledBox
						css={{
							display: 'inline-flex',
							padding: '4px 12px',
							alignItems: 'flex-start',
							gap: '10px',
							borderRadius: '20px',
							backgroundColor: redFlag
								? theme.error400
								: theme.warning500,
						}}
					>
						<StyledText
							variant="buttonSmall"
							css={{ width: 'max-content', color: 'white' }}
						>
							{`Grado ${intensityGrade}`}
						</StyledText>
					</StyledBox>
				</StyledBox>
				<StyledText
					variant="body3"
					css={{
						color: redFlag ? theme.error400 : theme.warning500,
					}}
				>
					{intensityDescription}
				</StyledText>
			</StyledBox>
		</StyledBox>
	);
};

export default SymptomReportModal;
