import React, { useEffect } from 'react';
import {
	StyledBox,
	StyledColumn,
	StyledRow,
	StyledScreen,
} from '../../../common/styledCommonComponents';
import HungerAndThirstChart from '../../../components/HungerAndThirstChart';
import SocialAndPhysicalActivitiesChart from '../../../components/SocialAndPhysicalActivitiesChart';
import PatientProfileCard from '../../../components/PatientProfileCard';
import { useParams } from 'react-router-dom';
import { useGetPatientMedicDataSummaryQuery } from '../../../redux/api/patientApi';
import ReportComponent from '../components/ReportComponent';
import { OncologicalSummaryCard } from '../components/OncologicalSummaryCard';
import { CurrentTreatmentList } from '../components/CurrentTreatmentList';
import MoodAndPainGraph from '../../../components/MoodAndPainGraph';
import { ProctcaeSummary } from '../components/ProctcaeSummary';
import BiometricChart from '../../../components/BiometricChart';
import { StyledCircularProgress } from '../../../components/CustomCircularProgress/styles';
import { NotFoundMessage } from '../components/NotFoundMessage';
import MetricChart from '../../../components/MetricChart';
import StudyBlock from '../components/StudyBlock';
import Card from '../../../components/Card';
import { StyledCardHome } from '../../../components/Card/styles';
import PatientSymptomsSummaryCard from '../../../components/PatientSymptomsSummaryCard';

const ProfileScreen = () => {
	const { patientId } = useParams();

	const {
		data: summaryData,
		refetch,
		isLoading,
		isError,
	} = useGetPatientMedicDataSummaryQuery(patientId, { skip: !patientId });

	return (
		<StyledScreen
			css={{
				display: 'flex',
				flexDirection: 'row',
				padding: '0 30px',
				justifyContent: 'center',
			}}
		>
			{isLoading ? (
				<StyledColumn
					css={{
						alignItems: 'center',
						justifyContent: 'center',
						width: '100%',
						height: '100%',
						display: 'inline-flex',
						flexDirection: 'column',
						gap: '16px',
					}}
				>
					<StyledCircularProgress size={50} />
				</StyledColumn>
			) : isError ? (
				<NotFoundMessage />
			) : (
				<StyledRow css={{ columnGap: '20px' }}>
					<StyledColumn
						css={{
							rowGap: '20px',
							padding: '30px 0px',
							overflowY: 'auto',
						}}
					>
						<PatientProfileCard />
						<ProctcaeSummary />
						<PatientSymptomsSummaryCard />
						<StyledRow css={{ columnGap: '20px' }}>
							<HungerAndThirstChart />
							<SocialAndPhysicalActivitiesChart />
						</StyledRow>
						<StyledRow css={{ columnGap: '20px' }}>
							<MoodAndPainGraph />
							<MetricChart />
						</StyledRow>
						<StyledCardHome
							css={{ flex: 1, gap: 0, padding: 0 }}
						></StyledCardHome>
					</StyledColumn>
					<StyledColumn
						css={{ rowGap: '20px', flex: 1, padding: '30px 0' }}
					>
						{
							/// <StudyBlock />
						}
						<OncologicalSummaryCard />
						<CurrentTreatmentList />
						<ReportComponent />
					</StyledColumn>
				</StyledRow>
			)}
		</StyledScreen>
	);
};

export default ProfileScreen;
