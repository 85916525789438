import styled from 'styled-components';

export const StyledSymptomTable = styled('table')`
	width: 100%;
	vertical-align: center;
	text-align: center;
	border: none;
	border-spacing: none;

	td,
	th {
		white-space: nowrap;
	}

	& > thead > tr > th {
		padding: 16px;
	}

	& > tbody > tr {
		&:hover {
			// background-color: ${(props) => props.theme.gray50};
			cursor: pointer;
		}

		& td {
			padding: 16px 0px;
		}
	}
`;
